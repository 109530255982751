import {ref} from 'vue';
import {useRuntimeConfig} from '#app';
import * as Sentry from '@sentry/nuxt';

export const usePushNotifications = () => {
    const isPushSupported = ref(false);
    const pushEnabled = ref(false);
    const config = useRuntimeConfig().public;
    const publicVapidKey = config.publicVapidKey;
    const apiUrl = config.apiBaseUrl;

    if (typeof window !== 'undefined') {
        isPushSupported.value = 'PushManager' in window;
    }

    const subscribeUser = async () => {
        if (!isPushSupported.value) return;

        try {
            const swRegistration = await navigator.serviceWorker.ready;
            const subscription = await swRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
            });

            await $fetch(`${apiUrl}/push-subscriptions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(subscription),
            });

            pushEnabled.value = true;
            setLocalStorage('subscription', JSON.stringify(subscription));
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error subscribing user:', error);
        }
    };

    const unsubscribeUser = async () => {
        if (!isPushSupported.value) return;

        try {
            const swRegistration = await navigator.serviceWorker.ready;
            const subscription = await swRegistration.pushManager.getSubscription();
            if (subscription) {
                await subscription.unsubscribe();

                await $fetch(`${apiUrl}/push-subscriptions`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({endpoint: subscription.endpoint}),
                });
                setLocalStorage('push-notifications', 'no');
                localStorage.removeItem('subscription');
            }
            pushEnabled.value = false;
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error unsubscribing user:', error);
        }
    };

    const updateUserCategories = async (categories: number[]) => {
        let storedSubscription = getLocalStorage('subscription');
        if (storedSubscription) {
            try {
                if (typeof storedSubscription === 'string') {
                    storedSubscription = JSON.parse(storedSubscription);
                }
                await $fetch(`${apiUrl}/update-category-preferences`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({endpoint: storedSubscription.endpoint, categories}),
                });
            } catch (error) {
                Sentry.captureException(error);
                console.error('Error updating user categories:', error);
            }
        } else {
            console.error('No stored subscription found.');
        }
    };

    const urlBase64ToUint8Array = (base64String: string) => {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    const getLocalStorage = (key: string) => {
        const value = localStorage.getItem(key);
        try {
            if (key === 'push-notifications') {
                return value;
            } else if (key === 'categories-to-notify') {
                return value ? value.split(',').map(Number) : [];
            } 
                return value ? JSON.parse(value) : null;
            
        } catch (error) {
            Sentry.captureException(error);
            console.error(`Error parsing localStorage key "${key}":`, error);
            return null;
        }
    };

    const setLocalStorage = (key: any, value: any) => {
        localStorage.setItem(key, value);
    };

    return {
        isPushSupported,
        pushEnabled,
        subscribeUser,
        unsubscribeUser,
        getLocalStorage,
        setLocalStorage,
        updateUserCategories,
    };
};
